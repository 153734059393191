import React, { useEffect, useState } from "react";
import styled,{ ThemeProvider } from "styled-components";
import ReplyOutlinedIcon from "@mui/icons-material/ReplyOutlined";
import Comments from "../components/Comments";
import Card from "../components/Card";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { dislike, fetchSuccess, like } from "../redux/videoSlice";
import { subscription } from "../redux/userSlice";
import MenuFull from "../components/Menu copy";
import { darkTheme, lightTheme } from "../utils/Theme";
import { axiosInstance } from "../config";
import { loginFailure, loginStart, loginSuccess } from "../redux/userSlice";
import { auth, provider } from "../firebase";
import { signInWithPopup } from "firebase/auth";

const Container = styled.div`
  display: flex;
  // gap: 24px;
  position: satic;
 // margin-top: 10dvh;
 margin:0;
 margin-left:40dvh;
 min-height:100dvh;
 
`;
const Main = styled.div`
  width:100%;
  background-color: ${({ theme }) => theme.bg};
`;
const Wrapper = styled.div`
  padding: 22px 95px;
`;

const Content = styled.div`
  flex: 5;
`;
const VideoWrapper = styled.div``;

const Title = styled.h1`
  font-size: 18px;
  font-weight: 400;
  margin-top: 20px;
  margin-bottom: 10px;
  word-break: normal;
  overflow-wrap: anywhere;
  color: ${({ theme }) => theme.text};
`;


const Info = styled.span`
  color: ${({ theme }) => theme.textSoft};
`;

const Buttons = styled.div`
  display: flex;
  gap: 20px;
  color: ${({ theme }) => theme.text};
  align-items: flex-end;
`;

const Button = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  
`;

const Hr = styled.hr`
  margin: 15px 0px;
  border: 0.5px solid ${({ theme }) => theme.soft};
`;

const Channel = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ChannelInfo = styled.div`
  display: flex;
  gap: 20px;
  align-items: flex-end;
`;

const Image = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
`;

const ChannelDetail = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.text};
`;

const ChannelName = styled.span`
  font-weight: 400;
`;

const Description = styled.p`
  font-size: 14px;
  word-break: normal;
  overflow-wrap: anywhere;
  color: ${({ theme }) => theme.text};
`;

const VideoFrame = styled.video`
  height: 85dvh;
  width: 100%;
  object-fit: cover;
  border-radius:10px;
`;
const Container1 = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #d7d7d7;
  display: flex;
  align-items: center;
  justify-content: center;
//   align-items: flex-start;
  z-index: 1;
  
`;

const Wrapper1 = styled.div`
  max-width: 500px;
  // height: 300px;
  background-color: #ededed;
  background-color: ${({ theme }) => theme.bgLighter};
  color: ${({ theme }) => theme.text};
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
  border-radius: 10px;
`;

const Title1 = styled.h5`
  text-align: left;
  // color: #555;
  // font-weight:bold;
  padding-bottom:20px;
`;
const Hh41 = styled.h4`
  text-align: left;
  padding:0;
  margin:0;
  word-break:normal;
  overflow-wrap:anywhere;
`;
const Button1 = styled.button`
  border-radius: 10px;
  border: none;
  padding: 10px 20px;
  font-weight: 500;
  cursor: pointer;
  background-color: #444;
  color: white;
  width:fit-content;
  align-items:left; 
`;

const Video = () => {
  const { currentUser } = useSelector((state) => state.user);

    const VideoComp = () => {
      const [isCopied, setIsCopied] = useState(false);  
      const { currentVideo } = useSelector((state) => state.video);
      const dispatch = useDispatch();
      const [darkMode, setDarkMode] = useState(false);

      const path = useLocation().pathname.split("/")[2];

      const [channel, setChannel] = useState({});

      const formatDate = (date) => {
        return new Date(date).toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'long',
          day: 'numeric'
        });
      };

      useEffect(() => {
        const fetchData = async () => {
          try {
            const videoRes = await axiosInstance.get(`/videos/find/${path}`);
            const channelRes = await axiosInstance.get(
              `/users/find/${videoRes.data.userId}`
            );
            setChannel(channelRes.data);
            dispatch(fetchSuccess(videoRes.data));
          } catch (err) {}
        };
        fetchData();
      }, [path, dispatch]);
      console.log(channel)

    
      const handleShare = () => {
        const videoLink = window.location.href;
        navigator.clipboard.writeText(videoLink);
        setIsCopied(true);
      };

      return (
        <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
        <MenuFull darkMode={darkMode} setDarkMode={setDarkMode} />
        <Container style={{}}>
        
        <Main>
        {/* <Navbar /> */}
        <Wrapper>
          <Content>
            <VideoWrapper >
              <VideoFrame  src={currentVideo && currentVideo.videoUrl} controls />
            </VideoWrapper>
            <Channel>
              <ChannelInfo>
                <Image src={channel.img} />
                <ChannelDetail>
                  <Title style={{padding:"0", margin:"0"}}><h4>{currentVideo &&currentVideo.title}</h4></Title>
                  <ChannelName>{channel.name} • <Info>
              {currentVideo && formatDate(currentVideo.createdAt)}
              </Info></ChannelName>              
                </ChannelDetail>
              </ChannelInfo>
              <Buttons>
                <Button onClick={handleShare}>
                <ReplyOutlinedIcon /> {isCopied ? "Copied!" : "Copy link"}
                </Button>
              </Buttons>
            </Channel>
            <Hr />
            <Description>{currentVideo &&currentVideo.desc}</Description>
            <Hr />
            <Comments videoId={currentVideo &&currentVideo._id} />
          </Content>
          </Wrapper>
          </Main>
        </Container>
        </ThemeProvider>
      );
    };

    const SignIn = () => {

      const dispatch = useDispatch();
    
      const signInWithGoogle = async () => {
        dispatch(loginStart());
        signInWithPopup(auth, provider)
          .then((result) => {
            axiosInstance
              .post("/auth/google", {
                name: result.user.displayName,
                email: result.user.email,
                img: result.user.photoURL,
              })
              .then((res) => {
                console.log(res)
                dispatch(loginSuccess(res.data));
              });
          })
          .catch((error) => {
            dispatch(loginFailure());
          });
      };
    
      return (
        <Container1 >
          <Wrapper1 >
          <Hh41>You are not signed-in!</Hh41>
          <Title1>Please, sign-in using your google account</Title1>       
            <Button1 onClick={signInWithGoogle}>Signin</Button1>
          </Wrapper1>
        </Container1>
      );
    };
return (
  <>
    {currentUser ? (
      <VideoComp />
    ) : (
      <SignIn />
    )}
  </>
);
    
  }


export default Video;
